import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components';
import evazLogo from '../../images/logo.png'
import alfaLogo from '../../images/partners/alfa-energia.png'
import eiLogo from '../../images/partners/exameinformatica_logo.png'
import { Button } from '@material-ui/core';
import { AppContext } from '../../contexts/AppContext';
import ScrollToTop from '../../components/common/ScrollToTop';
import MobilityPricesTable from '../../components/plans/MobilityPricesTable_new';
import MobilityDiscountPricesTable from '../../components/plans/MobilityDiscountPricesTable_new';
import ResidentialDiscountPricesTable from '../../components/plans/ResidentialDiscountPricesTable';
import VoltDiscountPricesTable from '../../components/plans/VoltDiscountPricesTable_new';
import PeakHoursTable from '../../components/plans/PeakHoursTable';
import TAR from '../../components/plans/TAR_new';
import NavBack from '../../components/common/NavBack';
import Modal from '../../components/common/Modal';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Link } from '@reach/router';
import { Redirect } from '@reach/router'
import { navigate } from 'gatsby';


const WarnContainer = styled.div`
    width: 80%;
    margin: auto;
    background-color: #ff771e;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    padding: 0 20px;
`

const WarnMessage = styled.h3`
    color: white;
    font-weight: 400;
    font-size: 10pt;
`

export default function Plans() {
    const { appState, sendEvent } = useContext(AppContext);
    const [openTAR, setOpenTAR] = useState(false);

    useEffect(() => {
        navigate('/solutions/plans/');
      }, []);
      return null;
}

// export default function Plans() {
//     const { appState, sendEvent } = useContext(AppContext);
//     const [openTAR, setOpenTAR] = useState(false);

//     return (
//         <Layout>
//             <SEO title="Planos" />
//             <div className="plans-page-container">
//                 <ScrollToTop />
//                 <div>
//                     <NavBack />
//                 </div>
//                 <div className="plans-pricestable-container vbox">
//                     <span className="title prices-title section_title">
//                         {appState.i18n["navs"]["plans"].toUpperCase()}
//                     </span>
//                     <span className="subtitle">{appState.i18n.plans.motto_newplans}</span>
//                 </div>

//                 <Link to={"/plans_old"}>
//                     <WarnContainer>
//                         <WarnMessage>{appState.i18n.plans.price_warning}</WarnMessage>
//                     </WarnContainer>
//                 </Link>

//                 {/* ##################### MOBILITY PLAN SECTION ###################  */}
//                 <div className="plans-hero-container">
//                     <div className="title">
//                         <span>{appState.i18n["plans"]["mobilityPlan"].toUpperCase()}</span>
//                     </div>
//                 </div>
//                 <div className="plans-pricestable-container" style={{ marginBottom: "10px" }}>
//                     <div className="hbox plans-table-subtitle-container">
//                         <div className="subtitle plans-table-subtitle">{appState.i18n.plans.plan_prices.toUpperCase()}</div>
//                         <img alt="evaz logo" className="plans-evazlogo sidebar-logo-img" src={evazLogo} />
//                     </div>
//                     <MobilityPricesTable />
//                     <label>{appState.i18n.plans.egme_tariff}</label>
//                 </div>
//                 <div className="plans-pricestable-container">
//                     <Button onClick={() => {
//                         setOpenTAR(true);
//                         sendEvent("Plans", "Click TAR", "Mobility");
//                     }}>+ {appState.i18n.plans.seeTAR}</Button>
//                     <div className="reg-plans-signing-conditions-container ">
//                         <div>
//                             <span className="subtitle reg-plans-signing-conditions-title">{appState.i18n["registration"]["plan_sign_conditions_title"]}</span>
//                         </div>
//                         <div className="vbox reg-plans-signing-conditions-list">
//                             <div>{appState.i18n["registration"]["plan_condition1"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition2"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition3"]}</div>
//                         </div>
//                     </div>
//                 </div>

//                 {/* ##################### RESIDENTIAL PLAN SECTION ###################  */}
//                 <div className="plans-hero-container">
//                     <div className="title ">
//                         <span>{appState.i18n["plans"]["residentialPlan"].toUpperCase()}</span>
//                     </div>
//                 </div>
//                 <div className="plans-pricestable-container" style={{ marginBottom: "50px" }}>
//                     <div className="hbox plans-table-subtitle-container">
//                         <div className="subtitle plans-table-subtitle">{appState.i18n.plans.mobilityPrices.toUpperCase()}</div>
//                         <img alt="evaz logo" className="plans-evazlogo sidebar-logo-img" src={evazLogo} />
//                     </div>
//                     <MobilityDiscountPricesTable />
//                     <label>{appState.i18n.plans.egme_tariff}</label>

//                 </div>
//                 <div className="plans-pricestable-container" style={{ marginBottom: "50px" }}>
//                     <div className="hbox plans-table-subtitle-container">
//                         <div className="subtitle plans-table-subtitle">{appState.i18n.plans.residentialPricesTable.toUpperCase()}</div>
//                         <img alt="alfa energia logo" className="plans-evazlogo sidebar-logo-img" src={alfaLogo} />
//                     </div>
//                     {/* <ResidentialDiscountPricesTable /> */}
//                     <div style={{display: 'flex', justifyContent: 'center', maxWidth: '800px'}}>
//                         <p><a href="https://www.alfaenergia.pt/tarifas?social=1" target="_blank">{appState.i18n.plans.residential_external_link}</a></p>
//                     </div>
//                 </div>

//                 <div className="plans-pricestable-container">
//                     <Button onClick={() => {
//                         setOpenTAR(true);
//                         sendEvent("Plans", "Click TAR", "Residential");
//                     }}>+ {appState.i18n.plans.seeTAR}</Button>
//                     <div className="reg-plans-signing-conditions-container ">
//                         <div>
//                             <span className="subtitle reg-plans-signing-conditions-title">{appState.i18n["registration"]["plan_sign_conditions_title"]}</span>
//                         </div>
//                         <div className="vbox reg-plans-signing-conditions-list">
//                             <div>{appState.i18n["registration"]["plan_condition1"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition2"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition3"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition5"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition6"]}</div>
//                         </div>
//                     </div>
//                 </div>


//                 {/* ##################### VOLT PLAN SECTION ###################  */}
//                 <div className="plans-hero-container">
//                     <div className="title ">
//                         <span>{appState.i18n["plans"]["voltPlan"].toUpperCase()}</span>
//                     </div>
//                 </div>
//                 <div className="plans-pricestable-container" style={{ marginBottom: "50px" }}>
//                     <div className="hbox plans-table-subtitle-container">
//                         <div className="subtitle plans-table-subtitle">{appState.i18n.plans.voltPrices.toUpperCase()}</div>
//                         <div>
//                             <img className="plans-evazlogo" src={evazLogo} />
//                             <img className="plans-voltlogo" src={eiLogo} />
//                         </div>
//                     </div>
//                     <VoltDiscountPricesTable />
//                     <label>{appState.i18n.plans.egme_tariff}</label>
//                 </div>

//                 <div className="plans-pricestable-container">
//                     <Button onClick={() => {
//                         setOpenTAR(true);
//                         sendEvent("Plans", "Click TAR", "Volt");
//                     }}>+ {appState.i18n.plans.seeTAR}</Button>
//                     <div className="reg-plans-signing-conditions-container ">
//                         <div className="reg-plans-titles-container">
//                             <span className="subtitle reg-plans-signing-conditions-title">{appState.i18n["plans"]["voltBenefitsTitle"]}</span>
//                             <span className="reg-plans-benefits-subtitle">{appState.i18n["plans"]["voltBenefitsSubtitle"]}</span>
//                         </div>
//                         <div className="vbox reg-plans-signing-conditions-list">
//                             <h4 style={{marginBlockStart: "0px"}}>{appState.i18n["plans"]["voltBenefitsPaperTitle"]}</h4>
//                             <div>{appState.i18n["plans"]["volt_benefit1"]}</div>
//                             <div>{appState.i18n["plans"]["volt_benefit2"]}</div>
//                             <div>{appState.i18n["plans"]["volt_benefit3"]}</div>
//                             <h4>{appState.i18n["plans"]["voltBenefitsDigitalTitle"]}</h4>
//                             <div>{appState.i18n["plans"]["volt_benefit1"]}</div>
//                             <div>{appState.i18n["plans"]["volt_benefit2"]}</div>
//                             <div>{appState.i18n["plans"]["volt_benefit3_digital"]}</div>
//                             <Button style={{ marginTop: "20px" }} className="button-default shadow"><a href="https://loja.trustinnews.pt/exame-informatica-evaz/" target="_blank" >{appState.i18n.plans.volt_link}</a></Button>
//                         </div>
//                         <div>
//                             <div style={{marginTop: "70px"}}className="reg-plans-benefits-subtitle">{appState.i18n["plans"]["voltExistingSubscribers"]}</div>
//                             <h4  style={{marginLeft: "30px", paddingTop: "10px", fontSize: "10pt"}}>{appState.i18n["plans"]["voltExistingSubscribers_condition1"]}</h4>
//                         </div>
//                         <div style={{ marginTop: "40px" }}>
//                             <span className="subtitle reg-plans-signing-conditions-title">{appState.i18n["registration"]["plan_sign_conditions_title"]}</span>
//                         </div>
//                         <div className="vbox reg-plans-signing-conditions-list">
//                             <div>{appState.i18n["registration"]["plan_condition1"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition2"]}</div>
//                             <div>{appState.i18n["registration"]["plan_condition3"]}</div>
//                             <div>{appState.i18n["registration"]["volt_condition2"]}</div>
//                             <div>{appState.i18n["registration"]["volt_condition3"]}</div>
//                             {/* <div>{appState.i18n["registration"]["volt_condition4"]}</div> */}
//                             <div>{appState.i18n["registration"]["volt_condition5"]}</div>
//                             <div>{appState.i18n["registration"]["volt_condition6"]}</div>
//                         </div>
//                     </div>
//                 </div>



//                 <div className="plans-pricestable-container ">
//                     <div className="title" style={{ marginBottom: "20px" }}>{appState.i18n.plans.peakHoursSchedule.toUpperCase()}</div>
//                     <PeakHoursTable />
//                     <div className="plans_description"></div>

//                     <h4>{appState.i18n["registration"]["info_emptyhours_title"]}</h4>
//                     <p>{appState.i18n["registration"]["info_emptyhours_text"]}</p>

//                     <h4>{appState.i18n["registration"]["info_nonemptyhours_title"]}</h4>
//                     <p>{appState.i18n["registration"]["info_nonemptyhours_text"]}</p>
//                 </div>

//                 <div className="plans-pricestable-container">
//                     <div className="title" style={{ marginBottom: "20px" }}>{appState.i18n.plans.TAR.toUpperCase()}</div>
//                     <TAR />
//                     <div className="plans_description"></div>
//                 </div>

//                 <Modal
//                     title={appState.i18n.plans.seeTAR}
//                     content={<div>
//                         <TAR />
//                     </div>}
//                     actions={[{ onClick: () => { setOpenTAR(false); }, text: appState.i18n["navs"]["close"] }]}
//                     isOpen={[openTAR, setOpenTAR]} />
//             </div>
//         </Layout>
//     )
// }
